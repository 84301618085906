import { Location, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  skip,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ISeason, IUser } from '@lancelot-frontend/api';
import {
  RouteScrollBehaviour,
  RouterHistoryService,
} from '@lancelot-frontend/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import {
  APP_EMBEDDED_STORAGE_KEY,
  LOGIN_AS_STORAGE_KEY,
} from './app.constants';
import { Layouts } from './layout/app.layout';

@Injectable({
  providedIn: 'root',
})
export class AppService implements OnDestroy {
  private platformId = inject(PLATFORM_ID);
  private http = inject(HttpClient);
  private router = inject(Router);
  private location = inject(Location);
  private translocoService = inject(TranslocoService);
  private environmentService = inject(EnvironmentService);
  private routerHistoryService = inject(RouterHistoryService);

  private readonly destroy$ = new Subject<boolean>();

  isNativePlatform = Capacitor.isNativePlatform();
  isIOS = this.isNativePlatform && Capacitor.getPlatform() === 'ios';
  isAndroid = this.isNativePlatform && Capacitor.getPlatform() === 'android';
  isPlatformServer = isPlatformServer(this.platformId);
  isInIframe?: boolean | undefined = this.isPlatformServer
    ? undefined
    : window.self !== window.top;

  private readonly _layout$ = new BehaviorSubject<Layouts | undefined>(
    undefined,
  );
  get layout$() {
    return this._layout$.asObservable();
  }

  storage = new Map<string, string>();

  routerGuards = new Map<
    string,
    {
      options: {
        comeBackToRefusedUrlOnValueChange?: boolean;
      };
      urls: string[];
    }
  >();
  refusedUrl?: string;
  runningGuard = false;

  get layout() {
    return this._layout$.getValue();
  }

  set layout(layout) {
    this._layout$.next(layout);
  }

  private readonly _embedded$ = new BehaviorSubject<boolean>(this.embedded);
  get embedded$() {
    return this._embedded$.asObservable();
  }

  get embedded() {
    return this.getSessionStorageItem(APP_EMBEDDED_STORAGE_KEY);
  }

  set embedded(value) {
    this.setSessionStorageItem(APP_EMBEDDED_STORAGE_KEY, value);
    this._embedded$.next(value);
  }

  private readonly _loginAs$ = new BehaviorSubject<IUser['firebaseUid'] | null>(
    this.loginAs,
  );
  get loginAs$() {
    return this._loginAs$.asObservable();
  }

  get loginAs() {
    return this.getSessionStorageItem(LOGIN_AS_STORAGE_KEY);
  }

  set loginAs(value) {
    this.setSessionStorageItem(LOGIN_AS_STORAGE_KEY, value);
    this._loginAs$.next(value);

    if (!value) {
      // Remove query params
      this.router.navigate([window.location.pathname], {
        queryParams: {
          loginAs: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  }

  private readonly _loading$ = new BehaviorSubject<boolean>(true);
  get loading$() {
    return this._loading$.asObservable();
  }

  set loading(value) {
    this._loading$.next(value);
  }

  get loading() {
    return this._loading$.getValue();
  }

  private readonly _waiting$ = new BehaviorSubject<boolean>(false);
  get waiting$() {
    return this._waiting$.asObservable();
  }

  set waiting(value) {
    this._waiting$.next(value);
  }

  get waiting() {
    return this._waiting$.getValue();
  }

  private _loadingContentCount = 0;
  private readonly _loadingContent$ = new BehaviorSubject<boolean>(false);
  get loadingContent$() {
    return this._loadingContent$.asObservable();
  }

  get loadingContent() {
    return this._loadingContent$.getValue();
  }

  set loadingContent(loadingContent) {
    setTimeout(() => {
      if (loadingContent) {
        this._loadingContentCount += 1;
      } else if (this._loadingContentCount > 0) {
        this._loadingContentCount -= 1;
      }
      this._loadingContent$.next(this._loadingContentCount > 0);
    });
  }

  private _refreshingContentCount = 0;
  private readonly _refreshingContent$ = new BehaviorSubject<boolean>(false);
  get refreshingContent$() {
    return this._refreshingContent$.asObservable();
  }

  get refreshingContent() {
    return this._refreshingContent$.getValue();
  }

  set refreshingContent(refreshingContent) {
    setTimeout(() => {
      if (refreshingContent) {
        this._refreshingContentCount += 1;
      } else if (this._refreshingContentCount > 0) {
        this._refreshingContentCount -= 1;
      }
      this._refreshingContent$.next(this._refreshingContentCount > 0);
    });
  }

  private readonly _refreshingTimestamp$ = new BehaviorSubject<null | number>(
    null,
  );
  get refreshingTimestamp$() {
    return this._refreshingTimestamp$.asObservable();
  }

  get refreshingTimestamp() {
    return this._refreshingTimestamp$.getValue();
  }

  private readonly _connected$ = new BehaviorSubject<boolean>(true);
  get connected$() {
    return this._connected$.asObservable();
  }

  set connected(value) {
    this._connected$.next(value);
  }

  get connected() {
    return this._connected$.getValue();
  }

  private readonly _focused$ = new BehaviorSubject<boolean>(true);
  get focused$() {
    return this._focused$.asObservable();
  }

  set focused(value) {
    this._focused$.next(value);
  }

  get focused() {
    return this._focused$.getValue();
  }

  private readonly _cmsDown$ = new BehaviorSubject<boolean>(false);
  get cmsDown$() {
    return this._cmsDown$.asObservable();
  }

  set cmsDown(value) {
    this._loading$.next(false);
    this._cmsDown$.next(value);
  }

  get cmsDown() {
    return this._cmsDown$.getValue();
  }

  private readonly _cmsUnderMaintenance$ = new BehaviorSubject<boolean>(false);
  get cmsUnderMaintenance$() {
    return this._cmsUnderMaintenance$.asObservable();
  }

  set cmsUnderMaintenance(value) {
    this._loading$.next(false);
    this._cmsUnderMaintenance$.next(value);
  }

  get cmsUnderMaintenance() {
    return this._cmsUnderMaintenance$.getValue();
  }

  private readonly _apiDown$ = new BehaviorSubject<boolean>(false);
  get apiDown$() {
    return this._apiDown$.asObservable();
  }

  set apiDown(value) {
    this._loading$.next(false);
    this._apiDown$.next(value);
  }

  get apiDown() {
    return this._apiDown$.getValue();
  }

  private readonly _apiUnderMaintenance$ = new BehaviorSubject<boolean>(false);
  get apiUnderMaintenance$() {
    return this._apiUnderMaintenance$.asObservable();
  }

  set apiUnderMaintenance(value) {
    this._loading$.next(false);
    this._apiUnderMaintenance$.next(value);
  }

  get apiUnderMaintenance() {
    return this._apiUnderMaintenance$.getValue();
  }

  apiVersion?: `${number}.${number}.${number}` = this.isNativePlatform
    ? '1.10.0'
    : undefined;

  outdated$ = this.http
    .get<{ version: `${number}.${number}.${number}` } | null>(
      this.environmentService.get('apiBaseUrl') + '/public/version',
    )
    .pipe(
      catchError(() => {
        this.apiDown = true;
        return of(undefined);
      }),
      map((data) => {
        if (!data) {
          return false;
        }

        const { version } = data;

        if (!this.apiVersion) {
          this.apiVersion = version;
          return false;
        } else if (this.apiVersion !== version) {
          const [currentMajor, currentMinor] = this.apiVersion
            .split('.')
            .map((s) => parseFloat(s));
          const [newMajor, newMinor] = version
            .split('.')
            .map((s) => parseFloat(s));

          if (currentMajor === newMajor) {
            return currentMinor < newMinor;
          }

          return currentMajor < newMajor;
        }

        return false;
      }),
    );

  private readonly _loadedTranslationScopes$ = new BehaviorSubject<
    Record<string, boolean>
  >({});
  get loadedTranslationScopes$() {
    return this._loadedTranslationScopes$.asObservable();
  }

  canGoBack$ = this.routerHistoryService.canGoBack$;

  currentSeason$ = this.http
    .get<ISeason>(
      this.environmentService.get('apiBaseUrl') + '/seasons/current',
    )
    .pipe(
      catchError(() => {
        this.apiDown = true;
        return of(undefined);
      }),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

  constructor() {
    // Keep tracks of loaded translation scopes
    this.translocoService.events$
      .pipe(
        filter((e) => e.type === 'translationLoadSuccess'),
        takeUntil(this.destroy$),
      )
      .subscribe((e) => {
        this._loadedTranslationScopes$.next({
          ...this._loadedTranslationScopes$.getValue(),
          [e.payload.scope || 'main']: true,
        });
      });
  }

  setSessionStorageItem(key: string, value: unknown) {
    if (
      !this.isPlatformServer &&
      this.isInIframe === false &&
      typeof Storage !== 'undefined'
    ) {
      try {
        sessionStorage.setItem(key, JSON.stringify(value));
      } catch {
        this.storage.set(key, JSON.stringify(value));
      }
    } else {
      this.storage.set(key, JSON.stringify(value));
    }
  }

  getSessionStorageItem(key: string) {
    if (
      !this.isPlatformServer &&
      this.isInIframe === false &&
      typeof Storage !== 'undefined'
    ) {
      try {
        const sessionStorageValue = sessionStorage.getItem(key);
        if (sessionStorageValue) {
          return JSON.parse(sessionStorageValue);
        } else {
          const storageValue = this.storage.get(key);
          return storageValue ? JSON.parse(storageValue) : undefined;
        }
      } catch {
        const storageValue = this.storage.get(key);
        return storageValue ? JSON.parse(storageValue) : undefined;
      }
    } else {
      const storageValue = this.storage.get(key);
      return storageValue ? JSON.parse(storageValue) : undefined;
    }
  }

  removeSessionStorageItem(key: string) {
    if (
      !this.isPlatformServer &&
      this.isInIframe === false &&
      typeof Storage !== 'undefined'
    ) {
      try {
        sessionStorage.removeItem(key);
      } catch {
        // NOOP
      }
    } else {
      this.storage.delete(key);
    }
  }

  setLocalStorageItem(key: string, value: unknown) {
    if (
      !this.isPlatformServer &&
      this.isInIframe === false &&
      typeof Storage !== 'undefined'
    ) {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch {
        this.storage.set(key, JSON.stringify(value));
      }
    } else {
      this.storage.set(key, JSON.stringify(value));
    }
  }

  getLocalStorageItem(key: string) {
    if (
      !this.isPlatformServer &&
      this.isInIframe === false &&
      typeof Storage !== 'undefined'
    ) {
      try {
        const localStorageValue = localStorage.getItem(key);
        if (localStorageValue) {
          return JSON.parse(localStorageValue);
        } else {
          const storageValue = this.storage.get(key);
          return storageValue ? JSON.parse(storageValue) : undefined;
        }
      } catch {
        const storageValue = this.storage.get(key);
        return storageValue ? JSON.parse(storageValue) : undefined;
      }
    } else {
      const storageValue = this.storage.get(key);
      return storageValue ? JSON.parse(storageValue) : undefined;
    }
  }

  removeLocalStorageItem(key: string) {
    if (
      !this.isPlatformServer &&
      this.isInIframe === false &&
      typeof Storage !== 'undefined'
    ) {
      try {
        localStorage.removeItem(key);
      } catch {
        // NOOP
      }
    } else {
      this.storage.delete(key);
    }
  }

  addRouterGuard(
    name: string,
    url: string,
    observable: Observable<UrlTree | boolean>,
    options: { comeBackToRefusedUrlOnValueChange?: boolean } = {},
  ) {
    if (!this.routerGuards.has(name)) {
      this.routerGuards.set(name, { options, urls: [url] });

      observable
        .pipe(
          distinctUntilChanged((a, b) => {
            return a.toString() === b.toString();
          }),
          tap((value) => {
            if (
              (this.routerGuards.get(name)?.urls ?? []).includes(
                this.location.path(),
              )
            ) {
              const { comeBackToRefusedUrlOnValueChange = false } =
                this.routerGuards.get(name)?.options ?? {};
              if (value !== true && comeBackToRefusedUrlOnValueChange) {
                this.refusedUrl = this.location.path();
              }
            }
          }),
          skip(1),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          if (
            !this.runningGuard &&
            (this.routerGuards.get(name)?.urls ?? []).includes(
              this.location.path(),
            )
          ) {
            this.runningGuard = true;
            this.router
              .navigateByUrl(this.refusedUrl || this.location.path(), {
                replaceUrl: true,
                state: !this.refusedUrl
                  ? {
                      scrollBehavior: RouteScrollBehaviour.KEEP_POSITION,
                    }
                  : undefined,
              })
              .then(() => {
                this.runningGuard = false;
                if (this.location.path() === this.refusedUrl) {
                  this.refusedUrl = undefined;
                }
              });
          }
        });
    } else {
      const guard = this.routerGuards.get(name);
      if (guard && !guard.urls.includes(url)) {
        this.routerGuards.set(name, { options, urls: [...guard.urls, url] });
      }
    }

    return observable;
  }

  scopeLoaded(scope: string) {
    return this.loadedTranslationScopes$.pipe(
      filter((scopes) => scopes[scope]),
      map((scopes) => scopes[scope]),
      distinctUntilChanged(),
    );
  }

  refresh() {
    this._refreshingTimestamp$.next(Date.now());
  }

  back(
    options: NavigationExtras & {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fallbackRoute?: any[];
    } = {},
  ) {
    const { fallbackRoute = ['/'], ...navigationExtras } = options;
    if (this.routerHistoryService.canGoBack()) {
      this.location.back();
    } else {
      this.router.navigate(fallbackRoute, {
        replaceUrl: true,
        ...navigationExtras,
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
