export const ADMIN_PREVIOUS_ENTITY_PREF_KEY = 'previousAdminEntity';
export const DISABLED_NOTIFICATIONS_WARNING_PREF_KEY =
  'doNotDisplayDisabledNotificationsWarningDialogAnymore';
export const HIDDEN_NEWS_PREF_KEY = 'hiddenNews';
export const SIGNED_IN_USER_PREF_KEY = 'signedInUser';
export const SIGNED_IN_USER_EASI_TOKEN_PREF_KEY = 'signedInUserEASIToken';
export const SIGNED_IN_PERSON_PREF_KEY = 'signedInPerson';
export const REGISTER_FORM_BIRTHDATE_STORAGE_KEY = 'registerForm.birthdate';
export const REGISTER_AS_STUDENT_STORAGE_KEY = 'registerAsStudent';
export const REGISTER_AS_TEACHER_STORAGE_KEY = 'registerAsTeacher';
export const LOGIN_FORM_USERNAME_STORAGE_KEY = 'loginUserName';
export const LOGIN_AS_STORAGE_KEY = 'loginAs';
export const APP_EMBEDDED_STORAGE_KEY = 'embedded';
export const CONSENT_MODE_PREF_KEY = 'consentMode';
